import React from 'react';
import styled from 'styled-components';

// Styled Components
const PageContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f8fc;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.8rem;
  color: #1a4c9c;
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 1.3;
`;

const DatePublished = styled.p`
  font-size: 1.1rem;
  color: #666666;
  margin-bottom: 2rem;
  text-align: center;
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  margin-bottom: 2.5rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.9rem;
  color: #1a4c9c;
  border-bottom: 3px solid #1a4c9c;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const TextContent = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333333;
  text-align: justify;
  margin-bottom: 1.5rem;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 700px;
  display: block;
  margin: 1.5rem auto;
  border-radius: 8px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
`;

const SectionImage = styled.img`
  width: 100%;
  max-width: 700px;
  display: block;
  margin: 1.5rem auto;
  border-radius: 8px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
`;

const StyledList = styled.ul`
  margin-top: 1rem;
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
`;

const ListItem = styled.li`
  margin-bottom: 0.8rem;
  line-height: 1.8;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
  margin-bottom: 2rem;
`;

const Tag = styled.span`
  background-color: #1a4c9c;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  font-size: 0.9rem;
`;

const Button = styled.button`
  margin-top: 2.5rem;
  padding: 0.8rem 2.5rem;
  font-size: 1.1rem;
  color: #fff;
  background-color: #1a4c9c;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0f3570;
  }
`;

// Metadata for SEO
export const DataFundamentalsMetadata = {
  title: "Data Fundamentals",
  description:
    "A comprehensive overview of data fundamentals, including structure, storage, and formats.",
  tags: [
      'Data Fundamentals',
      'Structured Data',
      'Unstructured Data',
      'Data Storage',
      'Relational Databases',
      'NoSQL',
      'Data Formats',
      'Big Data Processing',
      'Cloud Storage Solutions',
      'Columnar Storage'
    ],
  datePublished: "2025-02-23",
  category: "Article",
  path: "/article/data-fundamentals",
  coverImage: "/images/data_basics.jpg",
};

// Main Component
const DataFundamentals = () => {
  const metadata = DataFundamentalsMetadata;

  return (
    <PageContainer>
      <Title>{metadata.title}</Title>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
        {metadata.tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagContainer>
      <br></br>
      {/* Section: What is Data? */}
      <ContentSection>
        <SectionTitle>What is Data?</SectionTitle>
        <TextContent>
          Data is the <strong>raw material</strong> of the modern world. It can be <strong>numbers, words, measurements, observations</strong>, or even just descriptions of things. In essence, data is the <strong>basis for decisions</strong> in virtually every field, from science to business. Think of data as the <strong>ingredients in a recipe</strong>. Just as a chef combines ingredients to create a delightful meal, organizations combine data to derive <strong>insights</strong> and drive <strong>innovation</strong>.
        </TextContent>
      </ContentSection>

      {/* Section: Structured vs. Unstructured Data */}
      <ContentSection>
        <SectionTitle>Structured vs. Unstructured Data</SectionTitle>
        <SectionImage src="/images/data_structure.png" alt="Data Structure" />
        <TextContent>
          Data can be broadly categorized into two types: <strong>structured</strong> and <strong>unstructured</strong>. Understanding the difference is crucial because it dictates how data is stored, accessed, and used.
        </TextContent>
        <TextContent>
          <strong>Structured Data</strong> is <strong>highly organized</strong>, typically stored in <strong>tables, spreadsheets, or relational databases</strong>. This type of data is <strong>easy to search</strong> and <strong>analyze</strong>. Consider it like a well-organized filing cabinet where every document is labeled and stored in its proper place.
        </TextContent>
        <TextContent>
          In contrast, <strong>unstructured data</strong> includes things like <strong>emails, images, videos, and social media posts</strong>. It does not follow a <strong>predefined format</strong>, making it more <strong>challenging to analyze</strong>. Think of unstructured data as a vast, unorganized library where information is scattered throughout. Despite this chaos, unstructured data is incredibly <strong>valuable</strong>.
        </TextContent>
        <TextContent >
        Companies like <strong>Amazon</strong> analyze both types—structured data for tracking purchases and unstructured data (like reviews) to gauge customer sentiment.
        </TextContent>
      </ContentSection>

      {/* Section: Fundamentals of Data Storage */}
      <ContentSection>
        <SectionTitle>Fundamentals of Data Storage</SectionTitle>
        <SectionImage src="/images/data_storage.jpeg" alt="Data Storage" />
        <TextContent>
          Storing data efficiently is as crucial as gathering it. In today’s digital era, <strong>data storage solutions</strong> form the <strong>backbone</strong> of both small businesses and multinational corporations. The right storage system ensures that data is not only <strong>safely preserved</strong> but also <strong>readily accessible</strong> when needed.
        </TextContent>
        <TextContent>
          Traditional <strong>relational databases</strong> provide robust support for <strong>transactions</strong> and <strong>data integrity</strong>. However, as data volume grows, <strong>cloud storage solutions</strong> have taken center stage. The <strong>flexibility and scalability</strong> of the cloud have revolutionized how we store and manage information. A notable example is a retail company that moved from on-premise databases to cloud storage, which resulted in <strong>faster query responses</strong> and <strong>substantial cost savings</strong>.
        </TextContent>
        <TextContent>
          Additionally, new storage architectures like <strong>data lakes</strong> allow organizations to store <strong>vast amounts of unstructured data</strong>. These innovations enable businesses to <strong>harness diverse data types</strong>—from text and images to sensor readings—allowing for a more <strong>comprehensive analysis</strong> of <strong>trends and opportunities</strong>.
        </TextContent>
      </ContentSection>

      {/* Section: Common Data Formats */}
      <ContentSection>
        <SectionTitle>Common Data Formats</SectionTitle>
        <SectionImage src="/images/data_formats.png" alt="Data Formats" />
        <TextContent>
          Data is stored and exchanged in a <strong>variety of formats</strong>, each suited for <strong>specific purposes</strong>. Here are some of the most common:
        </TextContent>
        <StyledList>
          <ListItem><strong>CSV:</strong> A simple, plain-text format that uses commas to separate values, making it ideal for spreadsheets and quick data transfers.</ListItem>
          <ListItem><strong>JSON:</strong> A flexible format for representing data as key-value pairs. Widely used in web APIs, JSON’s readability has made it a favorite among developers.</ListItem>
          <ListItem><strong>SQL Tables:</strong> Found in relational databases, these tables store data in a structured manner, ensuring consistency and integrity through predefined schemas.</ListItem>
          <ListItem><strong>Parquet:</strong> A columnar storage format designed for efficient data processing in big data applications.</ListItem>
          <ListItem><strong>Avro:</strong> A compact, schema-based data format commonly used in big data processing and serialization for fast and efficient data exchange.</ListItem>
          <ListItem><strong>ORC (Optimized Row Columnar):</strong> A highly efficient columnar storage format optimized for Hive and other big data applications, improving compression and query performance.</ListItem>
        </StyledList>
        <TextContent>
          Imagine organizing your personal documents. You might sort them by <strong>file type</strong> or <strong>date</strong>, which is similar to how choosing the right data format can <strong>streamline the process</strong> of data retrieval and analysis. The impact of selecting an appropriate format can be <strong>profound</strong>, affecting everything from <strong>processing speed</strong> to <strong>storage costs</strong>.
        </TextContent>
        <TextContent>
        <strong>Spotify</strong> uses <strong>JSON for its API responses</strong> to send song metadata, playlists, and user data between its mobile app and servers because it’s lightweight and easy to parse. However, for <strong>large-scale data storage and analytics</strong> Spotify uses formats like <strong>Parquet and Avro</strong>, which enable faster queries when processing millions of records.        </TextContent>
      </ContentSection>

      {/* Section: The Significance of Data Literacy */}
      <ContentSection>
        <SectionTitle>The Significance of Data Literacy</SectionTitle>
        <TextContent>
          In a world <strong>overwhelmed by data</strong>, the ability to <strong>understand and interpret</strong> this information is invaluable. Data literacy is not just for data scientists, it is a <strong>critical skill</strong> for everyone. Whether you're running a business, working in education, or pursuing a creative endeavor, understanding data can empower you to make <strong>smarter decisions</strong>.
        </TextContent>
        <TextContent>
          Consider the story of a <strong>mid-sized company</strong> that is struggling with <strong>stagnant growth</strong>. By investing in <strong>data literacy training</strong>, its employees could learn how to extract <strong>actionable insights</strong> from everyday data. This newfound capability could not only improve <strong>operational efficiency</strong> but also foster a <strong>culture of innovation</strong> that will help the company <strong>turn its fortunes around</strong>.
        </TextContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </PageContainer>
  );
};

export default DataFundamentals;