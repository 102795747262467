import React from 'react';
import styled from 'styled-components';

const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #c41e1e;
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const Author = styled.p`
  font-size: 1.2rem;
  color: #333333;
  margin-bottom: 1rem;
  font-style: italic;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const DatePublished = styled.p`
  font-size: 1rem;
  color: #555555;
  margin-bottom: 2rem;
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #c41e1e;
  border-bottom: 2px solid #c41e1e;
  padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333333;
`;

const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #fcebea;
  border-left: 4px solid #c41e1e;
  border-radius: 4px;
  color: #333333;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #333333;
`;

const OrderedList = styled.ol`
  padding-left: 1rem;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

const Tag = styled.span`
  background-color: #c41e1e;
  color: white;
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #c41e1e;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;

  &:hover {
    background-color: #8b0000;
  }
`;

export const CourageMetadata = {
  title: "The Courage to Be Disliked",
  description: "How to Free Yourself, Change your Life and Achieve Real Happiness",
  tags: ['Philosophy', 'Psychology', 'Adlerian', 'Self-Help', 'Happiness', 'Freedom', 'Relationships', 'Identity'],
  author: "Ichiro Kishimi and Fumitake Koga",
  datePublished: "2025-03-31",
  category: "Book Review",
  path: "/book-review/the-courage-to-be-disliked",
  coverImage: "/images/the_courage_to_be_disliked.jpg",
};

const CourageToBeDisliked = () => {
  const metadata = CourageMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
        {metadata.tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagContainer>

      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            The Courage to Be Disliked is a philosophical dialogue that brings the teachings of Alfred Adler to life through an engaging conversation between a philosopher and a young man. The book challenges the reader to rethink long-held beliefs about happiness, trauma, and interpersonal relationships.
          </p>
          <br />
          <p>
            Unlike traditional self-help books, this one dismantles the idea that our past determines our future. It promotes the empowering view that we can change our lives in any moment, simply by choosing to live differently. Its core messages revolve around freedom, detachment, and the pursuit of a meaningful life over one defined by validation.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem>Separation of Tasks: Understand what is and isn't your responsibility in relationships.</ListItem>
            <ListItem>Freedom vs. Approval: True freedom means letting go of the need for others’ recognition.</ListItem>
            <ListItem>Trauma Denial: The past does not determine the present. We live in the here and now.</ListItem>
            <ListItem>Living for Contribution: Life gains meaning when we contribute to others’ well-being.</ListItem>
            <ListItem>Interpersonal Relationships: All problems are interpersonal relationship problems.</ListItem>
            <ListItem>Self-Acceptance: Accepting yourself as you are, instead of striving to be "special."</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>“You are not the center of the world, and neither am I. That’s precisely why we are free.”</Quote>
          <Quote>“All problems are interpersonal relationship problems.”</Quote>
          <Quote>“You can be happy now. Not someday. Not when you’ve achieved something. Now.”</Quote>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            This book was an enjoyable and thought-provoking read. While some of the ideas initially felt jarring, particularly the rejection of trauma as a life-determining force, they became more compelling the deeper I got into Adler's logic. The perspective that we are not bound by our past but can choose how to live going forward is both practical and surprisingly optimistic.
          </p>
          <br />
          <p>
            The dialogue format made the content very accessible. It’s conversational, even playful at times, which made the heavier concepts easier to absorb. It's also a gentle introduction to Adlerian psychology, without the dryness you might expect from traditional philosophy or psychology texts.
          </p>
          <br />
          <p>
            One idea that didn’t sit fully right with me was the emphasis on completely letting go of the desire for recognition. While it’s true that living for others’ approval can be limiting, the notion that seeking any form of validation is inherently misguided felt a bit extreme. Still, the book invites you to think critically, rather than take everything at face value—and that’s part of its appeal. I found myself more engaged with the practical takeaways about how we relate to others and manage our own expectations than with the psychological theory behind it all.
            </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default CourageToBeDisliked;